import Error from 'next/error'
// import {serverSideTranslations} from "next-i18next/serverSideTranslations";

// export async function getStaticProps({ locale }) {
//     return {
//         props: {
//             ...(await serverSideTranslations(locale, ['common', 'navbar']))
//         }
//     }
// }

export default function Page() {return <Error statusCode={404} />}